.full-screen {
  height: 100%;
}

.signin {
  margin-top: 30px;
}

.custom-dark {
  background-color: rgb(41, 41, 41) !important;
}
